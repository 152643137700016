@import '/src/assets/styles/general.scss';

.set-password {


  &__container {
    width: 100%;
    background-color: $red;
    border-radius: 25px;
    padding: 27px 60px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
  }

  @media screen and (max-width: 1024px) {
    &__container {
      padding: 27px 27px 27px 50px;    
    }
  }

  &__container button {
    white-space: nowrap;
  }

  @media screen and (max-width: 768px) {
    &__container {
      padding: 25px 18px;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;      
    }
  }

  &__title {
    margin: 0;
    color: white;
    font-weight: 300;
    font-size: clamp(0.625rem, 0.483rem + 0.5825vw, 1rem);
    line-height: 1.1;
  }

  @media screen and (max-width: 768px) {
    &__title {
      min-width: 250px;
      text-align: center;
    }
  }

  &__container button {
    padding: 14px 36px;
  }

}