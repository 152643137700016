@font-face {
    font-family: 'Gill Sans Nova';
    src: url('GillSansLightC.eot');
    src: url('GillSansLightC.eot?#iefix') format('embedded-opentype'),
        url('GillSansLightC.woff2') format('woff2'),
        url('GillSansLightC.woff') format('woff'),
        url('GillSansLightC.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans Nova';
    src: url('GillSansNova-Book.eot');
    src: url('GillSansNova-Book.eot?#iefix') format('embedded-opentype'),
        url('GillSansNova-Book.woff2') format('woff2'),
        url('GillSansNova-Book.woff') format('woff'),
        url('GillSansNova-Book.ttf') format('truetype'),
        url('GillSansNova-Book.svg#GillSansNova-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

