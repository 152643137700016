@import './assets/styles/general.scss';

.app {
  min-height: 100vh;
  background-color: $page-bg-color;
  color: $light-text-color;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
}

.container {
  width: 100%;
}

.wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  padding: 0 24px;
  z-index: 10;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    max-width: 620px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 0 25px;
  }
}

.wrapper.landing {
  max-width: 1040px;
}

@media screen and (max-width: 1024px) {
  .wrapper.landing {
    max-width: 668px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper.landing {
    padding: 0 45px;
  }
}

.wrapper.account {
  max-width: 1208px;
}

@media screen and (max-width: 1024px) {
  .wrapper-account {
    padding: 0 109px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper-account {
    padding: 0 45px;
  }
}

.footer {
  background-color: $footer-bg-color;
  background-image: none;
}