@import '/src/assets/styles/general.scss';

.modal-paper {
  background-color: white;
  min-height: max-content;
  min-height: max-content;
  margin: 0 auto;
  // height: max-content;
  border-radius: $border-radius;
  padding: clamp(0.9375rem, 0.1092rem + 3.3981vw, 3.125rem) clamp(1.25rem, -0.6432rem + 7.767vw, 3.125rem);
  position: relative;
  margin: auto;

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__body {
    width: 100%;
  }

}

.close-btn {
  margin: 10px;
}

.close-btn img {
  background: transparent;
  width: clamp(0.9375rem, 0.8192rem + 0.4854vw, 1.25rem);
  height: clamp(0.9375rem, 0.8192rem + 0.4854vw, 1.25rem);
}