@import '/src/assets/styles/general.scss';

.button {
  border-radius: 13px;
  padding: 20px 54px;
  text-transform: uppercase;
  font-size: clamp(0.875rem, 0.7803rem + 0.3883vw, 1.125rem);
  line-height: 1.3;
  color: $light-text-color;
  font-family: 'Gill Sans Nova';
  border: none;
  outline: none;
  background-color: transparent;
}

@media screen and (max-width: 1024px) {
  .button {
    padding: 30px 45px;
  }
}

@media screen and (max-width: 768px) {
  .button {
    padding: 20px 48px;
  }
}

.button.purple {
  background: $purple-btn-bg;
}

.button.blue {
  background: $blue-btn-bg;
}

.button.white {
  background: white;

}

.border {
  border: 1px solid #5A7AB7;
}