@import '/src/assets/styles/general.scss';

.payment-details {

  &__details-section {

    & .title {
      margin-bottom: 30px;
    }

    & p {
      margin-top: 15px;
      font-family: $primary-font;
      font-size: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
      line-height: 1.1;
      color: $modal-text-color;
      font-weight: 300;
    }

    & p>span {
      font-weight: 100;
    }

    & .button {
      margin-top: 30px;
      padding: 16px 48px;
      font-size: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
      font-weight: 400;
      line-height: 1.3;
    }
  }

  &__select-country {
    margin-top: 30px;

    & .title {
      margin-bottom: 20px;
    }

    & .select {
      width: 100%;
      border-radius: 20px;
      border: 1px solid $border-color;
    }

    & .custom-dropdown {
      padding: 16px 20px;
    }
  }

  &__type {
    margin-top: 45px;

    & .title {
      margin-bottom: 25px;
    }

    & .checkbox-group {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .custom-checkbox__text {
        font-size: clamp(0.875rem, 0.8277rem + 0.1942vw, 1rem);
      }
    }

    @media screen and (max-width: 1024px) {
      & .checkbox-group {
        gap: 20px;
      }
    }

    & .button {
      margin-top: 30px;
      padding: 22px 66px;
      font-size: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
      font-weight: 300;
    }

    @media screen and (max-width: 768px) {
      & .button {
        width: 100%;
      }
    }

  }

}