@import '/src/assets/styles/general.scss';

.title {
  font-family: $primary-font;
  color: $dark-text-color;
  font-size: clamp(1rem, 0.9527rem + 0.1942vw, 1.125rem);
  line-height: 1.1; 
}

// @media screen and (max-width: 1024px) {
//   .title {
//     font-size: 20px;
//   }
// }

// @media screen and (max-width: 768px) {
//   .title {
//     font-size: 18px;
//   }
// }