@import '/src/assets/styles/general.scss';


.nav__container {
  background-color: $page-bg-color;
  max-width: 280px;
  transition: all 0.3s ease;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .nav__container {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    transform: translateX(-100%);
  }

  .nav__container.open {
    margin: 0 auto;
    width: 100%;
    transform: translateX(0);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    z-index: 99;
  }

  .menu-wrapper {
    margin: 0 auto;
    width: 540px;
    padding: 60px 0 145px;
  }
}

@media screen and (max-width: 768px) {
  .menu-wrapper {
    width: 100%;
    padding: 66px 45px;
  }
}

.nav__container-header {
  display: none;
}

@media screen and (max-width: 1024px) {
  .nav__container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.burger {
  margin-top: 0;
  padding: 0;
  display: none;
  background: none;
 
}

.burger img {
  width: clamp(0.875rem, 0.733rem + 0.5825vw, 1.25rem);
  height: auto;
}

@media screen and (max-width: 1024px) {
  .burger {
    display: block;
    position: relative;
    align-self: end;
  }
}

.nav__container-content {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
}

@media screen and (max-width: 1024px) {
  .nav__container-content {
    margin-top: 70px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .nav__container-content {
    margin-top: 50px;
    align-items: center;
  }
}

@media screen and (max-width: 450px) {
  .nav__container-content {
    margin-top: 50px;
    flex-direction: column;
    align-items: start;
  }
}

.balance {
  background-color: white;
  border-radius: $border-radius;
  padding: clamp(1.875rem, 1.6383rem + 0.9709vw, 2.5rem);
  font-family: $primary-font;
  font-size: clamp(0.875rem, 0.7803rem + 0.3883vw, 1.125rem);
  line-height: 1.3;
  color: $page-bg-color;
}

@media screen and (max-width: 1024px) {
  .balance {
    padding: 30px 80px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .balance {
    padding: 30px 30px;
    text-align: center;
    align-self: center;
  }
}

@media screen and (max-width: 450px) {
  .balance {
    width: 100%;
  }
}


.service-order {
  margin-top: 28px;
}

@media screen and (max-width: 1024px) {
  .service-order {
    margin-top: 0px;
  }
}

@media screen and (max-width: 450px) {
  .service-order {
    margin-top: 28px;
  }
}

.service-order button {
  padding: 12px 48px;
  margin-top: 20px;
}

// nav__menu ---------------------------------------------------------------------
.nav__menu {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}


.nav__menu-block {
  width: max-content;
}

.support {
  grid-area: support;
}

.management {
  grid-area: management;
}

.payments {
  grid-area: payments;
}

.account {
  grid-area: account;
}

@media screen and (max-width: 1024px) {
  .nav__menu {
    display: grid;
    grid-template-areas:
      "support payments"
      "management account";
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 768px) {
  .nav__menu {
    margin-top: 50px;
    display: flex;
  }
}

.nav__menu-links {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  width: 246px;
  color: $account-link-color;

  &>button {
    display: flex;
    flex-direction: column;
    gap: 13px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    width: max-content;
    color: $account-link-color;
    background-color: transparent;
    margin-left: 0;
  }

  &>button:hover {
    color: $secondary-text-color;
  }

  @media (hover:hover) {
    &>button:hover {
      color: $secondary-text-color;
    }
  }
  
  @media (hover:none) {
    &>button:hover {
      color: inherit;
    }
  }

  &>button.active {
    color: $secondary-text-color;
  }

  @media screen and (max-width: 1024px) {
    &>button {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .nav__menu-links {
    font-size: 16px;
  }
}

.nav__menu-links a:hover {
  color: $secondary-text-color;
}

.active {
  color: $secondary-text-color;
}

.nav-container__footer {
  margin-top: 38px;
}

@media screen and (max-width: 768px) {
  .nav-container__footer {
    margin-top: 40px;
  }
}

.nav-container__footer .button {
  padding: 16px 48px;
}