@import '/src/assets/styles/general.scss';


.footer {
  line-height: 1.2;
  letter-spacing: 2%;
  color: $footer-link-color;


  &__container {
    display: flex;
    justify-content: space-between;
    padding: 59px 0;
  }

  @media screen and (max-width: 768px) {
    &__container {
      flex-direction: column;
      padding: 63px 0 62px;
      gap: 40px;

    }
  }

  &__nav {
    max-width: 215px;
  }

  &__nav-title {
    font-size: clamp(1rem, 0.9527rem + 0.1942vw, 1.125rem);
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__nav-links {
    font-size: clamp(0.875rem, 0.8277rem + 0.1942vw, 1rem);
    font-weight: 300;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

}
