@import '/src/assets/styles/general.scss';

.top-up-balance {



  .amount-input {
    font-family: $primary-font;
    color: $modal-text-color;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    margin-bottom: 20px;
    padding: 16px 24px;
    border-radius: 20px;
    background-color: #f5f5f5;

    input {
      background: inherit;
      width: 100%;
      white-space: wrap;
    }

    input::placeholder {
      font-weight: 300;
    }

    button {
      padding: 14px 32px;
      border: 1px solid $border-color;
      font-size: 16px;
      border-radius: 10px;
      background-color: #f5f5f5;
      font-weight: 300;

      cursor: pointer;

      &:hover {
        background-color: #bbb;
      }

      @media (hover:hover) {
        &:hover {
          background-color: #bbb;
        }
      }
      
      @media (hover:none) {
        &:hover {
          background-color: inherit;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .amount-input {
      flex-direction: column;
      align-items: start;
      gap: 15px;
    }
  }

  & h2 {
    margin: 40px 0 0 40px;
  }

  .payment-methods {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .payment-method {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      border: 1px solid $border-color;
      border-radius: 10px;
      cursor: pointer;
      transition: background-color 0.3s;

      @media screen and (max-width: 1024px) {
        .payment-method {
          padding: 30px;
        }
      }

      @media screen and (max-width: 768px) {
        .payment-method {
          padding: 20px;
        }
      }

      &.selected {
      }

      &.disabled {
        opacity: 0.3;
      }

      &:hover {
        background-color: #F6F6F6;
      }

      @media (hover:hover) {
        &:hover {
          background-color: #F6F6F6;
        }
      }
      
      @media (hover:none) {
        &:hover {
          background-color: inherit;
        }
      }

      .payment-logo {
        margin-right: 20px;

        img {
          width: clamp(8.5rem, 6.9854rem + 6.2136vw, 12.5rem);
          height: auto;
        }
      }

      .payment-info {
        h3 {
          // margin: 0 0 5px 0;
          color: $dark-text-color;
          font-size: clamp(0.875rem, 0.7803rem + 0.3883vw, 1.125rem);
          font-weight: 600;
        }

        p {
          margin-top: 3px;
          font-size: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
          font-weight: 300;
          color: $modal-text-color;
        }

      }
    }
  }
}