@import '/src/assets/styles/general.scss';

.hero {

  &__container {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 50px;
    z-index: 10;
  }

  @media screen and (max-width: 1024px) {
    &__container {
      padding: 14px 0 50px;
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      padding: 48px 0 50px;
    }
  }


  &__title {
    margin-top: 20px;
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    &__title {
      margin-top: 16px;
    }
  }

  @media screen and (max-width: 768px) {
    &__title {
      margin-top: 16px;
    }
  }

  &__title>span {
    font-family: inherit;
    display: block;
    color: $secondary-text-color;
  }

  @media screen and (max-width: 768px) {
    &__title>span {
      display: inline;
    }
  }

  &__container>button {
    margin-top: 45px;
  }

  @media screen and (max-width: 1024px) {
    &__container>button {
      margin-top: 30px;
    }
  }
}
