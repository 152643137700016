@import '/src/assets/styles/general.scss';

.lang_controls_menu {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  gap: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: fit-content;
}

.lang_controls_menu_label {
  padding: 0;
  width: 100%;
  gap: 0;
  bottom: 100%;
  top: auto;
}

.lang_controls_lang {
  position: relative;
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 1024px) {
  .account-lang-button {
    display: none;
  }
}

.lang_controls_lang_label {
  height: auto;
  border: none;
  padding: 0;
  border-radius: 12px;

}

.lang_controls {
  display: flex;
  gap: 12px;

  &_label {
    padding: 20px 24px;
    display: flex;
    gap: 12px;
    border-radius: 12px;
    width: 100%;
  }

  &_active {
    background-color: $light-text-color;
  }
}

.lang_label {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}