@import '/src/assets/styles/general.scss';

.location {

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 74px 100px;
  }

  @media screen and (max-width: 1024px) {
    &__container {
      gap: 51px;
      padding: 50px 40px;
    }
  }

  @media screen and (max-width: 1024px) {
    &__container {
      gap: 51px;
      padding: 50px 0;
    }
  }

  &__title {
    max-width: 648px;
    text-align: center;
    border-radius: 20px;
    padding: 1em 0.5em;
  }

  @media screen and (max-width: 1024px) {
    &__title {
      max-width: 540px;
      font-size: 22px;
    }
  }

  &__title>span {
    font-family: inherit;
    color: $primary-text-color;
  }

  &__img {
    max-width: 100%;
    z-index: 20;
  }

  @media screen and (max-width: 1024px) {
    &__img {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 768px) {
    &__img {
      padding: 0;
    }
  }
}

// стили для поинтов ---------------------------------------------------------------

.location__map-container {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 99;
}

.location__img {
  width: 100%;
  height: auto;
  display: block;
}

.location__points {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
}

.location__point {
  position: absolute;
  transform: translate(-50%, -100%);
  cursor: pointer;
  width: 2.5vw;
  max-width: 22px;
  min-width: 8px;
}

.location__info-box {
  width: 100%;
  max-width: 120px;
  min-width: 80px;
  position: absolute;
  top: 20px;
  left: -20px;
  // background-color: white;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  // opacity: 1;
  font-family: $primary-font;
  z-index: 30;

}

.location__info-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}

.location__info-box h4 {
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
}

.location__info-box p {
  margin-top: 5px;
  font-size: 8px;
  font-weight: 300;
  color: $light-text-color;
}

.location__info-box button {
  height: 8px;
  width: 12px;
  background: transparent;
}