@import '/src/assets/styles/general.scss';

input[type="range"] {
  accent-color: $secondary-text-color;
}

.range-slider {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.slider {
  width: 100%;
  background: transparent;
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    height: 8px;
    background: transparent;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid $border-color;
    border-radius: 50%;
    cursor: pointer;
    transform: translateY(-25%);
    position: relative;
    z-index: 1;
    filter: drop-shadow(4px 4px 8px rgba(12, 17, 29, 0.1));
  }

  &::-moz-range-track,
  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: white;
    border: 1px solid $border-color;
    border-radius: 50%;
    cursor: pointer;
  }
}

.slider-labels {
  width: 96%;
  display: flex;
  justify-content: space-between;
  font-size: clamp(0.3125rem, 0.2338rem + 0.3704vw, 0.5625rem);

  &-top {
    margin-bottom: 25px;
  }

  &-top>span {
    color: $secondary-text-color;
    font-weight: 400;
  }

  &-bottom {
    margin-top: 25px;
    font-weight: 300;

  }

  &-bottom>span {
    color: $modal-text-color;
  }

  span {
    white-space: pre-wrap;
    text-align: center;
  }

}

.slider-track-wrapper {
  position: relative;
  margin: 0 4%;
}

.slider-track-lines {
  margin: 0 2%;
  position: absolute;
  top: 138%;
  width: 96%;
  height: 100%;
  transform: translateY(-50%);
  pointer-events: none;

  .track-line {
    position: absolute;
    width: 1px;
    height: 10px;
    background-color: $border-color;
    transform: translateX(0%);
    transition: opacity 0.3s;
  }
}