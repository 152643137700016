@import '/src/assets/styles/general.scss';

.all-tickets {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 450px) {
    &__header {
      flex-direction: column;
      align-items: center;
    }
  }

  &__header p {
    margin: 0 0 0 40px;
    font-size: 16px;
    line-height: 1.3;
  }

  @media screen and (max-width: 1024px) {
    &__header p {
      font-size: 14px;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    &__header p {
      margin: 0;
    }
  }

  @media screen and (max-width: 450px) {
    &__header p {
      text-align: center;
    }
  }

  &__header .button {
    font-size: clamp(0.875rem, 0.8277rem + 0.1942vw, 1rem);
    font-weight: 400;
    padding: 16px 48px;
  }

  &__content {
    margin-top: 22px;

    & .dashboard-table {
      min-height: 300px;
    }
  }
}