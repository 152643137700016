@import '/src/assets/styles/general.scss';

.custom-dropdown {
  border: 1px solid $border-color;
  border-radius: 15px;
  position: relative;
  transition: all 0.3s ease;
  color: $dark-text-color;

  & .dropdown-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 8px;
  }

  & .dropdown-icon {
    transition: transform 0.5s ease;
    width: clamp(0.9375rem, 0.7998rem + 0.6481vw, 1.375rem);
  }

  & .dropdown-header .dropdown-icon.open {
    transform: rotate(180deg);
  }

  & .dropdown-header p {
    display: inline;
    width: 100%;
    color: black;
    font-weight: 300;
  }

  & .dropdown-list {
    font-family: $primary-font;
    font-size: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
    color: $modal-text-color;
    font-weight: 300;
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 15px;
    position: absolute;
    transform: translateY(20%);
    left: 0;
    z-index: 2;
    overflow: hidden;

    &>li {
      padding: clamp(0.75rem, 0.6713rem + 0.3704vw, 1rem);
      background-color: white;
      cursor: pointer;
    }
  }
}