@import '/src/assets/styles/general.scss';

.advantages {
  &__container {
    padding: 0 100px 82px;
  }

  @media screen and (max-width: 1024px) {
    &__container {
      padding: 0 0 90px;
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      padding: 0 0 46px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 70px;
    width: max-content;
  }

  @media screen and (max-width: 1024px) {
    &__items {
      gap: 54px;
    }
  }

  &__item {
    padding: 16px;
    border-radius: 25px;
  }

  &__item-icon {
    position: relative;
    width: 60px;
    height: 60px;
    background: $icon-bg;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
  }

  &__item>h3,
  &__item>span {
    margin-left: 3px;
  }

  &__item h3 {
    margin-top: 20px;
    line-height: 1.2;
  }
  
  &__item:nth-child(1) h3 {
    max-width: 240px;
  }

  &__item:nth-child(2) h3 {
    width: 358px;
  }

  &__item:nth-child(3) h3 {
    max-width: 397px;
  }

  @media screen and (max-width: 768px) {
    &__item:nth-child(1) h3 {
      max-width: 190px;
    }
  
    &__item:nth-child(2) h3 {
      width: 250px;
      max-width: 100%;
    }
  
    &__item:nth-child(3) h3 {
      max-width: 230px;
    }
  }

  &__item span {
    font-family: $secondary-font;
    color: $tight-text-color;
    letter-spacing: 0.2px;
    max-width: 300px;
    font-size: clamp(1rem, 0.9053rem + 0.3883vw, 1.25rem);
    display: block;
    margin-top: 15px;
    line-height: 1.2;
    font-weight: 300;
  }

  @media screen and (max-width: 768px) {
    &__item span {
      width: 250px;
      margin-top: 10px;
    }
  }

}