@font-face {
    font-family: 'Unbounded';
    src: url('Unbounded-Light.eot');
    src: url('Unbounded-Light.eot?#iefix') format('embedded-opentype'),
        url('Unbounded-Light.woff2') format('woff2'),
        url('Unbounded-Light.woff') format('woff'),
        url('Unbounded-Light.ttf') format('truetype'),
        url('Unbounded-Light.svg#Unbounded-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unbounded';
    src: url('Unbounded-Medium.eot');
    src: url('Unbounded-Medium.eot?#iefix') format('embedded-opentype'),
        url('Unbounded-Medium.woff2') format('woff2'),
        url('Unbounded-Medium.woff') format('woff'),
        url('Unbounded-Medium.ttf') format('truetype'),
        url('Unbounded-Medium.svg#Unbounded-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unbounded Blond';
    src: url('Unbounded-Blond.eot');
    src: url('Unbounded-Blond.eot?#iefix') format('embedded-opentype'),
        url('Unbounded-Blond.woff2') format('woff2'),
        url('Unbounded-Blond.woff') format('woff'),
        url('Unbounded-Blond.ttf') format('truetype'),
        url('Unbounded-Blond.svg#Unbounded-Blond') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unbounded';
    src: url('Unbounded-Regular.eot');
    src: url('Unbounded-Regular.eot?#iefix') format('embedded-opentype'),
        url('Unbounded-Regular.woff2') format('woff2'),
        url('Unbounded-Regular.woff') format('woff'),
        url('Unbounded-Regular.ttf') format('truetype'),
        url('Unbounded-Regular.svg#Unbounded-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

