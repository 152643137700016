@import 'src/assets/styles/general.scss';

.logo {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: 'Unbounded';
  font-size: clamp(1.125rem, 0.983rem + 0.5825vw, 1.5rem);
  font-weight: 300;
  color: $logo-txt-color;
}

.logo>img:last-child {
  transform: rotate(180deg);
}

.account-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  font-family: 'Unbounded';
  font-size: clamp(0.875rem, 0.733rem + 0.5825vw, 1.25rem);
  font-weight: 300;
  color: $logo-txt-color;

  &__span {
    font-weight: 400;
  }
}
