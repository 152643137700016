@import '/src/assets/styles/general.scss';

.notification {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 38px 40px;
  background-color: $notification-bg-color;
  border-radius: 20px;
  gap: 25px;
  margin: 10px 0;


  &__message {
    font-size: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
    line-height: 1.1;
    font-weight: 300;
    color: $modal-text-color;
    white-space: wrap;
  }

  &__close-btn img {
    width: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
    height: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
  }
}

@media screen and (max-width: 1024px) {
  .notification {
    padding: 33px 20px;
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .notification {
    padding: 24px 32px;
    gap: 20px;
  }
}