@import 'assets/styles/general.scss';

.contact-support {
  font-weight: 300;
  max-width: 430px;  
  margin: 30px 20px;
}

p {  
  font-size: clamp(0.75rem, 0.6553rem + 0.3883vw, 1rem);
  line-height: 1.3;
  color: $modal-text-color;

  & a {
    font-weight: 400;
    color: $secondary-text-color;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  p {
    max-width: 290px;
  }
}