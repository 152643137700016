@import '/src/assets/styles/general.scss';

.platforms {
  &__container {
    padding: 130px 0 100px;
    position: relative;
    border-radius: 15px;
  }

  @media screen and (max-width: 1024px) {
    &__container {
      padding: 110px 0 100px;
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      padding: 70px 0 75px;
    }
  }

  &__scrollbar {
    display: none;
    flex-direction: column;
    align-items: center;
    color: #536280;
    font-size: 14px;
    font-weight: 300;
    width: 100%;

    &-header {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;
    }

    &-text {
      text-align: center;
    }

    &-track {
      width: 100%;
      height: 8px;
      background-color: #131B2C;
      border-radius: 46px;
      position: relative;
      cursor: pointer;
      margin-bottom: 20px;
    }

    &-thumb {
      height: 100%;
      width: 84px;
      background-color: #202E4B;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(0);
      transition: transform 0.1s;
    }
  }

  &__table-container {
    overflow-x: hidden;
  }

  @media screen and (max-width: 768px) {
    &__scrollbar {
      display: flex;
    }

    &__table-container {
      overflow-x: auto;
      margin-top: 30px;      
    }

    &__table {
      border-collapse: collapse;
      width: 100%;
    }
  }

  &__table {
    margin-top: 20px;
    width: 100%;
    min-width: 620px;
    table-layout: fixed;
    border-collapse: collapse;
  }

  &__table td {
    text-align: center;
    font-weight: 300;
    color: #536280;
    overflow: hidden;
  }

  &__table-header th {
    padding: 0;
    vertical-align: middle;
    position: relative;
  }

  &__table-header th:first-child {
    background: none;
  }

  &__table-header--item {
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 300;
    padding: 8px 16px;
    width: max-content;
    border-radius: 22px;
    background: #131B2C;
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    &__table-header--item {
      font-size: 10px;
    }
  }

  &__table-header th>img {
    margin-right: 10px;
  }

  @media screen and (max-width: 1024px) {
    &__table-header th>img {
      margin-right: 4px;
    }
  }

  &__table-body {
    border: 1px solid #2B3750;
    border-radius: 15px;
  }

  &__table-body tr {
    border: 1px solid #2B3750;
  }

  &__table-body tr:first-child,
  &__table-body tr:last-child {
    border: none;
  }

  &__table-body th {
    text-align: start;
    font-weight: 400;
    color: #b3c1da;
    max-width: 230px;
    padding: 0 36px 0 30px;
    background-color: #131B2C;
    // overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    &__table-body th {
      padding: 14px 20px;
      font-size: 12px;
      max-width: 120px;
    }
  }

  &__table-row th {
    min-width: 230px;
  }

  &__table-row:first-child th {
    border-top-left-radius: 15px;
  }

  &__table-row:last-child th {
    border-bottom-left-radius: 15px;
  }

  &__table-ceil {
    padding: 27px 47px;
    background-color: $page-bg-color;
  }

}

.legend {
  margin-top: clamp(1.5rem, 1.1214rem + 1.5534vw, 2.5rem);
  font-size: clamp(0.75rem, 0.608rem + 0.5825vw, 1.125rem);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 28px;
  margin-right: 38px;

  &__item {
    margin-right: clamp(0rem, -0.9466rem + 3.8835vw, 2.5rem);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: clamp(1rem, 0.8107rem + 0.7767vw, 1.5rem);
  }
}

@media screen and (max-width: 450px) {
  .legend {
    justify-content: space-between;
  }
}