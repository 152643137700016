@import '/src/assets/styles/general.scss';

.dashboard {
  width: 100%;
  background-color: white;
  border-radius: 30px;
  padding: 47px 60px;
  color: $grey;
}

@media screen and (max-width: 1024px) {
  .dashboard {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    padding: 28px 20px;

  }
}