@import '/src/assets/styles/general.scss';

.features {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 66px;
    align-items: end;
    position: relative;
    padding: 90px 100px 60px;
  }

  @media screen and (max-width: 1024px) {
    &__container {
      padding: 90px 0px 60px;
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      align-items: end;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 65px;
  }

  // &__content::before {
  //   content: "";
  //   position: absolute;
  //   top: 94px;
  //   left: -32px;
  //   width: 63px;
  //   height: 63px;
  //   border-radius: 50%;
  //   background: linear-gradient(90deg, rgba(49, 71, 108, 0.35) 0%, rgba(49, 71, 108, 0.28) 100%);
  //   backdrop-filter: blur(14px);
  //   opacity: 0.9;
  //   filter: drop-shadow(0, 1.2, 14px, rgba(0, 0, 0, 0.25));
  //   z-index: 10;
  // }

  // &__content::after {
  //   content: "";
  //   position: absolute;
  //   right: -55px;
  //   bottom: 80px;
  //   width: 210px;
  //   height: 210px;
  // }

  &__content-items {
    max-width: 400px;
    padding: 63px 57px 63px 108px;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media screen and (max-width: 1024px) {
    &__content-items {
      max-width: 354px;
      padding: 54px 54px 54px 92px;
      gap: 25px;

    }
  }

  @media screen and (max-width: 768px) {
    &__content-items {
      max-width: 280px;
      padding: 42px 44px 42px 81px;
      gap: 20px;

    }
  }

  &__content-item {
    font-family: $secondary-font;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 2%;
    font-size: clamp(1rem, 0.858rem + 0.5825vw, 1.375rem);
    font-weight: lighter;
    position: relative;    
    color: $tight-text-color;
    z-index: 99;
  }

  &__content-item span {
    font-family: $secondary-font;
    font-weight: 400;
    color: $light-text-color;
  }

  &__content-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: -48px;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 52px;
  }

  &__content-item:nth-child(1)::before {
    background: url('/assets/images/icons/privacy-level.png') no-repeat center;
  }

  &__content-item:nth-child(2)::before {
    background: url('/assets/images/icons/security.png') no-repeat center;
  }

  &__content-item:nth-child(3)::before {
    background: url('/assets/images/icons/speed.png') no-repeat center;
  }

  &__content-item:nth-child(4)::before {
    height: 100%;
    background: url('/assets/images/icons/price.png') no-repeat center;
  }

}

.guarantee {
  width: 272px;
  padding: 1em 0.5em;
  border-radius: 20px;

  &__text {
    color: white;
    font-family: $secondary-font;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 300;
  }

  &__text>span {
    font-family: $secondary-font;
    color: $secondary-text-color;
    line-height: 1.3;
    opacity: 1;
    font-weight: 400;
    font-size: 18px;
  }
}