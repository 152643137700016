.circle {
  position: absolute;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: linear-gradient(90deg, rgba(49, 71, 108, 0.35) 0%, rgba(49, 71, 108, 0.28) 100%);
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  opacity: 0.9;
  filter: drop-shadow(0, 1.2, 14px, rgba(0, 0, 0, 0.25));
  z-index: 1;
  min-width: 60px;
  min-height: 60px;
  will-change: filter;
  pointer-events: none;

  &.purple {
    background: linear-gradient(90deg, rgba(195, 123, 217, 0.35) 0%, rgba(195, 123, 217, 0.28) 100%);
    filter: drop-shadow(0, 1.2, 14px, rgba(195, 123, 217, 0.673));
  }
}