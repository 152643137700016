@import '/src/assets/fonts/Unbounded/Unbounded.scss';
@import '/src/assets/fonts/GillSans/GillSansNova.scss';


// -------------------- fonts -------------------- 

$title-font: 'Unbounded Blond';
$primary-font: 'Unbounded';
$secondary-font: 'Gill Sans Nova';

// -------------------- colors -------------------- 

$page-bg-color: #0A0E19;
$global-bg-color: #1E1D26;
$footer-bg-color: #04060B;

$border-color: #D9D9D9;

$logo-txt-color: #8199D6;
$light-text-color: #ffffff;
$modal-text-color: #6F6F6F;
$dark-text-color: #0A0E19;
$primary-text-color: #5A7AB7;
$secondary-text-color: #C37BD9;
$btn-txt-color: #8496C5;
$footer-link-color: #B3C1DA;
$account-link-color: #A9A9A9;
$notification-bg-color: #F3F3F3;
$tight-text-color: #ACB8CB;

$header-icon-bg: #2B3750;
$header-gradient-icon-bg: linear-gradient(180deg, rgba(43,55,80,1) 0%, rgba(43,55,80,0) 100%);
$icon-bg: rgb(49, 71, 108, 0.2);
// $icon-bg: rgba(66, 83, 115, 0.2);

$primary-btn-bg: transparent;
$header-btn-bg: linear-gradient(338deg, rgba(121, 155, 209, 0.49) -150%, rgba(40, 60, 91, 0.51) 100%);
$purple-btn-bg: radial-gradient(ellipse at center -50px, rgb(206, 134, 228) 0%, rgb(120, 42, 178) 100%);
$blue-btn-bg: radial-gradient(ellipse at center -50px, rgba(156,185,240,1) 0%, rgba(90,122,183,1) 100%);

$red: #C54C4C;
$grey: #F3F3F3;


// -------------------- size -------------------- 

$border-size: 0.78px;
$border-radius: 30px;